import React, { Component } from "react"
import { graphql, Link, navigate } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import i18Data from '../../content/intl/labels.json';
import { genPrefix, getTranslations, categoriesStringToSlugsArray } from "../components/helpers/helpers";

import "../styles/publications.scss"

class Publications extends Component {

    render() {
        const { data: { pageData, pubData }, pageContext: { lang, prefix, slug, tag, area, publicationsSearchPageSlug } } = this.props;
        const page = pageData.edges.filter(({ node }) => node.frontmatter.lang === lang)[ 0 ]?.node;
        const translations = getTranslations(pageData.edges, "publications");
        const i18 = i18Data[ lang ];

        const hasTagOrArea = !!tag || !!area;
        const tagObj = tag ? categoriesStringToSlugsArray([ tag ])[ 0 ] : null,
            tagSlug = tagObj?.slug;

        const areaObj = area ? categoriesStringToSlugsArray([ area ])[ 0 ] : null,
            areaSlug = areaObj?.slug

        let allTags = [], allAreas = [];
        pubData.edges.forEach(({ node }) => {
            allTags = allTags.concat((node.frontmatter.categories || "").split(",").map(s => s.trim()));
            allAreas = allAreas.concat((node.frontmatter.areas || "").split(",").map(s => s.trim()));
        });

        allTags = allTags.filter((s, i) => s && allTags.indexOf(s) === i).sort();
        allAreas = allAreas.filter((s, i) => s && allAreas.indexOf(s) === i).sort();

        const dataFiltered = !hasTagOrArea 
            ? pubData.edges.filter(art => art.node.frontmatter.order > 0).sort(sortDate) : (
                tagSlug ?
                    pubData.edges.filter(({ node }) => categoriesStringToSlugsArray(node.frontmatter.categories).some(c => c.slug === tagSlug)).sort(sortDate)
                    : pubData.edges.filter(({ node }) => categoriesStringToSlugsArray(node.frontmatter.areas).some(c => c.slug === areaSlug)).sort(sortDate)
                );

        const dataFilteredIds = dataFiltered.map(art => art.node.frontmatter.translateKey);

        const dataLatest = !hasTagOrArea ?
            pubData.edges.filter(art => art.node.frontmatter.stash === 0 && dataFilteredIds.indexOf(art.node.frontmatter.translateKey) < 0).sort(sortDate).filter((_, idx) => idx < 10)
            : null;

        const subTitle = !hasTagOrArea
            ? i18.labels.publicationsHighlights
            : `${page.frontmatter.title}: ${dataFiltered.length}`;

        const titleH1 = !hasTagOrArea
            ? page.frontmatter.summary
            : (tagObj ? `<small>${i18.labels.category}</small> ${tagObj.text}` : `<small>${i18.labels.area}</small> ${areaObj.text}`);

        const searchUrl = `${genPrefix(lang, true)}${publicationsSearchPageSlug}`;

        return (
            <Layout mainClassName="publications" data={{}} noActiveMenu={false} pageContext={this.props.pageContext} translations={translations}>

                <SEO title={page.frontmatter.title} description={page.frontmatter.summary} lang={lang ? lang : "en" }/>


                <div className={`publications-container initial-padding ${hasTagOrArea ? "tag-page" : ""}`}>

                    <div className="publications-top main-page">
                        <h1 dangerouslySetInnerHTML={{ __html: titleH1 }}></h1>
                        
                        {!hasTagOrArea &&
                        <div className="pub-search">
                            <h2 className="hx-area">{i18.labels.search}</h2>
                            <form
                                onSubmit={event => 
                                    {
                                        event.preventDefault();
                                        navigate(`${searchUrl}?q=${this.searchTextInput.value}`);
                                    }
                                }
                                >
                            <input 
                                name="searchText" type="search" 
                                placeholder={i18.labels.searchBox} aria-label={i18.labels.searchBox}
                                ref={(t) => { this.searchTextInput = t }} />
                            <button className="search-go" 
                                aria-label={i18.labels.searchGo}>
                                    <span className="big-link"></span></button>
                            </form>
                            <br />
                            <Link to={searchUrl}>&rarr; {i18.labels.searchAdv}</Link>
                        </div>
                        }
                    </div>

                    <div className="columns">
                        <div className="column-left">
                            <h2 className="hx-area">{subTitle}</h2>
                            <div className={`publications-content`}>
                                { dataFiltered.map((art, idx) =>
                                    <article className={`article-n-${idx}`} key={art.node.frontmatter.slug}>
                                        <Link to={`${genPrefix(lang, true)}${page.frontmatter.slug}/${art.node.frontmatter.slug}`}>
                                            <span dangerouslySetInnerHTML={{ __html: art.node.frontmatter.card }} /><span></span>
                                            <span className="big-link"></span>
                                        </Link>
                                    </article>
                                )}                        
                            </div>
                        </div>
                        <div className="column-right">
                            { dataLatest && 
                            <>
                                <h2 className="hx-area">{i18.labels.publicationsLatest}</h2>
                                <div className={`publications-content`}>
                                { dataLatest.map((art, idx) =>
                                    <article className={`article-n-${idx}`} key={art.node.frontmatter.slug}>
                                        <Link to={`${genPrefix(lang, true)}${page.frontmatter.slug}/${art.node.frontmatter.slug}`}>
                                            <span dangerouslySetInnerHTML={{ __html: art.node.frontmatter.card }} /><span></span>
                                            <span className="big-link"></span>
                                        </Link>
                                    </article>
                                )}                        
                            </div>
                            </>
                            }
                        </div>
                    </div>
                    

                    <div className="top-relations first-pad-top">
                        <div className="relations-section">
                            <h3>{i18.labels.categories}</h3>
                            <ul>
                            {categoriesStringToSlugsArray(allTags).map(cat => {
                                return(
                                    <li key={cat.slug} className={`${cat.slug === tagSlug ? "active" : ""}`}>
                                    <Link key={cat.slug} to={`${prefix}/${slug}/tag/${cat.slug}`} rel="category tag">
                                        {cat.text}
                                    </Link>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                      
                        <div className="relations-section">
                            <h3>{i18.labels.areas}</h3>
                            <ul>
                            {categoriesStringToSlugsArray(allAreas).map(cat => {
                                return(
                                    <li key={cat.slug} className={`${cat.slug === areaSlug ? "active" : ""}`}>
                                    <Link key={cat.slug} to={`${prefix}/${slug}/area/${cat.slug}`} rel="category tag">
                                        {cat.text}
                                    </Link>
                                    </li>
                                )
                            })}
                            </ul>
                        </div>
                        
                    </div>

                </div>

            </Layout>
        )
    }
}

export default Publications


export const query = graphql`
    query PublicationsQuery($lang: String!) {
        pageData: allMarkdownRemark(filter: { frontmatter: { template:{ eq: "publications" }} }) {
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        summary
                        title
                    }
                    id
                    html
                }
            }
        }
        pubData: allMarkdownRemark(
            filter: { frontmatter: { lang: { eq: $lang }, template:{ eq: "publication" }}},
        ){
            edges {
                node {
                    frontmatter {
                        lang
                        template
                        slug
                        title
                        translateKey
                        order
                        card
                        pubDate
                        categories
                        areas
                        order
                        stash
                    }
                }
            }
        }

    }
`
/*
function sortOrderThenDate(a, b) {
    let aD = new Date(a.node.frontmatter.pubDate),
        bD = new Date(b.node.frontmatter.pubDate),
        aS = a.node.frontmatter.order,
        bS = b.node.frontmatter.order,
        orderDate = aD === bD ? 0 : (aD > bD ? -1 : 1),
        orderOrder = aS === bS ? 0 : (aS > bS ? -10 : 10);

    return orderOrder + orderDate;
}
*/
function sortDate(a, b) {
    let aD = new Date(a.node.frontmatter.pubDate),
        bD = new Date(b.node.frontmatter.pubDate),
        orderDate = aD === bD ? 0 : (aD > bD ? -1 : 1);

    return orderDate;
}